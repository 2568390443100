<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: function () {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 50
                }
              }
            ],
            xAxes: [
              {
                type: 'time'
              }
            ]
          }
        }
      }
    }
  },
  mounted: function () {
    this.renderChart(this.data, this.options)
  }
}
</script>
